<template>
  <HomeLayout ref="homeLayout" :sys-name="sysName" :all-menu-items="menuList" logout-url="/api/logout" change-pass-url="/api/account/changePass" @changMenu="menuInit" />
</template>

<script>
import HomeLayout from '@/components/HomeLayout'
import util from '@/common/js/util'
import { setWaterMark } from '@/common/js/addWater'
import ArrayUtils from '@/common/js/ArrayUtils'
export default {
  components: { HomeLayout },
  inject: ['sysName'],
  provide: {
    formatCommitData(result) {
      result.forEach(item => {
        item.status = item.status.ordinal
      })
      return result
    },
    user: () => {
      return JSON.parse(util.getFromSession('account'))
    },
    reFlushSession() {
      const temp = this.user()
      return this.$get('/api/login/getProject?userId=' + temp.account.id + '&roleName=' + temp.account.roleName).then(result => {
        temp.projectList = result.data
        util.saveToSession('account', JSON.stringify(temp))
      })
    },
    netMap: [
      { value: 1, label: '4G' },
      { value: 2, label: 'NB' },
      { value: 3, label: '好' },
      { value: 4, label: '中' },
      { value: 5, label: '差' },
      { value: 6, label: '无' }],
    getStage() {
      this.$get('/api/project/getStage').then(result => {
        this.stages = result.data
      })
    },
    getDevice() {
      return this.$get('/api/inspectDevice/getAll').then(result => {
        this.device = result.data
      })
    },
  },
  data() {
    return {
      inspectAndConstructionShow: true,
      stepProjectShow: true,
      statisticsShow: true,
      menuList: [
        { path: '/admin/account', name: '用户管理', iconCls: 'menu-icon fa fa-lg fa-user-o', role: ['AUTH_ACCOUNT', 'AUTH_MIN_ACCOUNT'] },
        { path: '/admin/project', name: '项目管理', iconCls: 'menu-icon fa fa-lg fa-table', role: 'AUTH_PROJECT' },
        { path: '/admin/material', name: '项目设备', iconCls: 'menu-icon fa fa-comments fa-lg', role: 'AUTH_INSPECT' },
        { path: '/admin/inspectAndConstruction', name: '综合施工', iconCls: 'menu-icon fa fa-lg fa-table', role: 'AUTH_INSPECT', show: () => this.inspectAndConstructionShow },
        { path: '/admin/inspect', name: '查勘管理', iconCls: 'menu-icon fa fa-lg fa-table', role: 'AUTH_INSPECT', show: () => this.stepProjectShow },
        { path: '/admin/construction', name: '施工管理', iconCls: 'menu-icon fa fa-lg fa-table', role: 'AUTH_INSPECT', show: () => this.stepProjectShow },
        { path: '/admin/completedStatistics', name: '已完工项目统计', iconCls: 'menu-icon fa fa-lg fa-th-large', role: 'AUTH_INSPECT', show: () => this.statisticsShow },
        { path: '/admin/unfinishedStatistics', name: '未完工项目统计', iconCls: 'menu-icon fa fa-lg fa-th-large', role: 'AUTH_INSPECT', show: () => this.statisticsShow },
        { path: '/admin/inspectDevice', name: '设备类型', iconCls: 'menu-icon fa fa-lg fa-table', role: ['AUTH_DEVICE', 'AUTH_DEVICE_SHOW'] },
        { path: '/admin/constructionDevice', name: '设备型号', iconCls: 'menu-icon fa fa-lg fa-table', role: ['AUTH_DEVICE', 'AUTH_DEVICE_SHOW'] },
        { path: '/admin/sysLog', name: '日志管理', iconCls: 'menu-icon fa fa-comments fa-lg', role: 'AUTH_SYSLOG' },
        { path: '/admin/sysParam', name: '系统参数管理', iconCls: 'menu-icon fa fa-ravelry fa-lg ', role: 'AUTH_SYS_PARAM' },
      ],
      auths: [],
    }
  },
  beforeCreate() {
    const acocount = JSON.parse(util.getFromSession('account'))
    if (!acocount) {
      window.location = '/'
    }

    const fullName = acocount.account.fullName
    if (fullName) {
      setWaterMark(fullName, '')
    } else {
      setWaterMark('BCloud', '')
    }
  },
  created() {
    this.menuInit()
  },
  methods: {
    menuInit() {
      const parse = JSON.parse(util.getFromSession('account'))
      if (parse.projectList.length === 0) {
        this.statisticsShow = this.inspectAndConstructionShow = this.stepProjectShow = false
      } else {
        this.statisticsShow = true
      }
      this.inspectAndConstructionShow = !!ArrayUtils.getArrayByKeyToObject(parse.projectList, 'type:ordinal', 1)?.length
      this.stepProjectShow = !!ArrayUtils.getArrayByKeyToObject(parse.projectList, 'type:ordinal', 0)?.length
    }
  }

}

</script>
