let ob
let ob2
let temp
const id = '1.23452384164.123412415'
let listener
const setWatermark = (str1, str2, setting) => {
  removeWatermark(listener)
  const can = document.createElement('canvas')
  // 设置canvas画布大小
  can.width = 150
  can.height = 80

  const cans = can.getContext('2d')
  cans.rotate(-20 * Math.PI / 180) // 水印旋转角度
  cans.font = '15px Vedana'
  cans.fillStyle = '#666666'
  cans.textAlign = 'center'
  cans.textBaseline = 'Middle'
  cans.fillText(str1, can.width / 2, can.height) // 水印在画布的位置x，y轴
  cans.fillText(str2, can.width / 2, can.height + 22)
  const div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '40px'
  div.style.left = '0px'
  div.style.opacity = '0.15'
  div.style.position = 'fixed'
  div.style.zIndex = '100000'
  div.style.width = document.documentElement.clientWidth + 'px'
  div.style.height = document.documentElement.clientHeight + 'px'
  div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat'
  temp = document.createElement('div')
  temp.id = 'water'
  temp.style = '-webkit-print-color-adjust: exact;'
  document.body.appendChild(temp)
  const shadom = temp.attachShadow({ mode: 'open' })
  shadom.appendChild(div)
  ob = new MutationObserver((mutations, observer) => {
    if (mutations[0].removedNodes.length > 0 && mutations[0].removedNodes[0].id === 'water') {
      ob.disconnect()
      setWatermark(str1, str2, setting)
    }
  })
  ob.observe(document.body, { childList: true })
  ob2 = new MutationObserver((mutations, observer) => {
    ob2.disconnect()
    setWatermark(str1, str2, setting)
  })
  ob2.observe(document.getElementById(temp.id), { attributeOldValue: true })
  ob2.observe(shadom, { childList: true, subtree: true, attributeOldValue: true })
  listener = () => {
    setWatermark(str1, str2, setting)
  }
  window.addEventListener('resize', listener)
  return temp
}
// 添加水印方法
export const setWaterMark = (str1, str2, setting) => {
  setWatermark(str1, str2, setting)
}

// 移除水印方法
export const removeWatermark = (fn) => {
  if (temp && document.getElementById(temp.id) !== null) {
    ob.disconnect()
    ob2.disconnect()
    window.removeEventListener('resize', fn)
    document.body.removeChild(document.getElementById(temp.id))
  }
}
