<template>
  <el-row class="container">
    <el-col :span="24" class="header">
      <el-col :span="10" class="logo" :class="collapsed?'logo-collapse-width':'logo-width'">
        <span @click="collapse">{{ collapsed ? '' : sysName }}</span>
      </el-col>
      <el-col id="route" :span="10">
        <div class="tools" @click.prevent="collapse">
          <i class="fa fa-align-justify"><strong class="title" style="padding-left:20px;">{{ $route.name }}</strong></i>
        </div>
      </el-col>
      <el-col :span="4" class="userinfo">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link userinfo-inner">
            {{ fullName }}<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="$refs.dialog.changeVisible()">
              修改密码
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-col>
    <el-col :span="24" class="main">
      <aside :class="collapsed?'menu-collapsed':'menu-expanded'">
        <!--导航菜单-->
        <el-menu v-show="!collapsed" id="mainMenu" :default-active="$route.path" router>
          <template v-for="item in menuItems">
            <template v-if="item.children">
              <el-submenu :key="item.path" :index="item.path">
                <template slot="title">
                  <i :class="item.iconCls" /><span slot="title">{{ item.name }}</span>
                </template>
                <template v-for="subItem in item.children">
                  <el-menu-item v-if="roles.includes(subItem.role)" :key="subItem.path" :index="subItem.path">
                    {{ subItem.name }}
                  </el-menu-item>
                </template>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item :key="item.path" :index="item.path">
                <i :class="item.iconCls" /><span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
        <!--导航菜单-折叠后-->
        <ul v-show="collapsed" ref="menuCollapsed" class="el-menu collapsed">
          <li v-for="(item,index) in menuItems" :key="index" class="el-submenu item">
            <template v-if="item.children">
              <div class="el-submenu__title" style="padding-left: 20px;" @mouseover="showMenu(index,true)" @mouseout="showMenu(index,false)">
                <i :class="item.iconCls" />
              </div>
              <ul class="el-menu submenu" :class="'submenu-hook-'+index" @mouseover="showMenu(index,true)" @mouseout="showMenu(index,false)">
                <li v-for="child in item.children" :key="child.path" class="el-menu-item" style="padding-left: 40px;" :class="$route.path===child.path?'is-active':''" @click="$router.push(child.path)">
                  {{ child.name }}
                </li>
              </ul>
            </template>
            <template v-else>
              <th class="el-submenu">
                <el-tooltip class="item" effect="dark" :content="item.name" placement="right">
                  <div class="el-submenu__title " style="padding-left: 20px;height: 56px;line-height: 56px;" :class="$route.path===item.path?'is-active':''" @click="$router.push(item.path)">
                    <i :class="item.iconCls" />
                  </div>
                </el-tooltip>
              </th>
            </template>
          </li>
        </ul>
      </aside>
      <section class="content-container">
        <div class="grid-content bg-purple-light">
          <el-col :span="24" class="content-wrapper">
            <transition name="fade" mode="out-in">
              <router-view :user="user" @changMenu="$emit('changMenu')" />
            </transition>
          </el-col>
        </div>
      </section>
    </el-col>

    <!--新增界面-->
    <simple-dialog ref="dialog" title="修改密码" :close-on-click-modal="false">
      <el-form ref="changePassForm" :model="changePassForm" label-width="100px" :rules="changePassRules">
        <el-form-item label="新密码" prop="newPass">
          <el-input v-model.trim="changePassForm.newPass" auto-complete="off" type="password" />
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmNewPass">
          <el-input v-model.trim="changePassForm.confirmNewPass" auto-complete="off" type="password" />
        </el-form-item>
      </el-form>
      <div class="NumFooter">
        <el-button @click.native="$refs.dialog.close()">
          取消
        </el-button>
        <el-button type="primary" :loading="$store.state.isLoading" @click.native="changePass">
          提交
        </el-button>
      </div>
    </simple-dialog>
  </el-row>
</template>

<script>
import util from '@/common/js/util'
import SimpleDialog from '@/components/simple/SimpleDialog'
export default {
  name: 'HomeLayout',
  components: { SimpleDialog },
  props: {
    sysName: { required: true, type: String },
    allMenuItems: { required: true, type: Array },
    changePassUrl: { required: true, type: String },
    logoutUrl: { required: true, type: String }
  },
  data() {
    const validateConfirmNewPass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请再次输新入密码'))
      } else if (value !== this.changePassForm.newPass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      collapsed: false,
      user: null,
      fullName: '',
      roles: [],
      changePassForm: { newPass: '', confirmNewPass: '' },
      changePassRules: {
        newPass: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        confirmNewPass: [{ required: true, trigger: 'blur', validator: validateConfirmNewPass }],
      },
    }
  },

  computed: {
    menuItems() {
      const roles = this.user.role ? this.user.role.authority.split(',') : []
      return this.allMenuItems.filter(u => {
        if (typeof u.show !== 'undefined' && !u.show()) {
          return false
        }
        if (!u.role) {
          return true
        }
        if (u.role instanceof Array) {
          let bool = false
          u.role.forEach(element => {
            if (roles.includes(element)) {
              bool = true
            }
          })
          return bool
        } else {
          return roles.includes(u.role)
        }
      })
    },
  },
  created() {
    const user = util.getFromSession('account')
    if (user) {
      this.user = JSON.parse(user)
      this.fullName = this.user.account.fullName
      this.roles = this.user.role ? this.user.role.authority.split(',') : []
    }
  },
  methods: {
    // out method start
    postChangePass(param) {
      this.$post(this.changePassUrl, param).then(() => {
        this.$message({
          message: '密码修改成功',
          type: 'success',
        })
        this.$refs.changePassForm.resetFields()
        this.$refs.dialog.close()
      })
    },
    async postLogout() {
      await this.$post(this.logoutUrl)
    },
    // 折叠导航栏
    collapse() {
      this.collapsed = !this.collapsed
      const mainMenu = document.getElementById('mainMenu')
      if (mainMenu.offsetWidth === 0) {
        mainMenu.style.width = '229px'
      }
    },
    // out method end
    changePass() {
      this.$refs.changePassForm.validate((valid) => {
        if (valid) {
          this.$confirm('确认修改密码吗?', '提示', {
            type: 'warning',
          }).then(() => {
            const param = {
              newPass: this.changePassForm.newPass,
            }
            this.postChangePass(param)
          })
        }
      })
    },
    // 退出登录
    logout() {
      this.$confirm('确认退出吗?', '提示', {
        // type: 'warning'
      }).then(() => {
        this.postLogout().then(() => {
          util.removeSession('account')
          window.location.href = '/login'
        })
      })
    },
    showMenu(i, status) {
      this.$refs.menuCollapsed.getElementsByClassName('submenu-hook-' + i)[0].style.display = status ? 'block' : 'none'
    },
  },
}
</script>

<style lang="scss">
@media only screen and (max-width: 800px) {
  .el-pagination__sizes {
    position: absolute;
    top: 9999px;
    right: 9999px;
  }
}
</style>

<style scoped lang="scss">
$color-primary: #20a0ff;
.container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;

  .header {
    height: 50px;
    line-height: 50px;
    background: $color-primary;
    color: #fff;

    .userinfo {
      text-align: right;
      padding-right: 35px;
      float: right;

      .userinfo-inner {
        cursor: pointer;
        color: #fff;

        img {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          margin: 10px 0 10px 10px;
          float: right;
        }
      }
    }

    .logo {
      //width:230px;
      height: 50px;
      font-size: 20px;
      padding-left: 20px;
      padding-right: 20px;
      border-color: rgba(238, 241, 146, 0.3);
      border-right-width: 1px;
      border-right-style: solid;

      img {
        width: 40px;
        float: left;
        margin: 10px 10px 10px 18px;
      }

      .txt {
        color: #fff;
      }
    }

    .logo-width {
      width: 230px;
    }

    .logo-collapse-width {
      width: 60px
    }

    .tools {
      padding: 0 23px;
      //width:14px;
      height: 50px;
      line-height: 50px;
      cursor: pointer;
    }
  }

  .main {
    display: flex;
    // background: #324057;
    position: absolute;
    top: 50px;
    bottom: 0;
    overflow: hidden;

    #mainMenu{
      overflow: hidden;
    }

    aside {
      flex: 0 0 230px;
      width: 230px;
      // position: absolute;
      // top: 0px;
      // bottom: 0px;
      .el-menu {
        height: 100%;
      }

      .collapsed {
        width: 60px;

        .item {
          position: relative;
        }

        .submenu {
          position: absolute;
          top: 0;
          left: 60px;
          z-index: 99999;
          height: auto;
          display: none;
        }

        overflow: hidden;
      }
    }

    .menu-collapsed {
      flex: 0 0 60px;
      width: 60px;
      overflow: hidden;
    }

    .menu-expanded {
      flex: 0 0 230px;
      width: 230px;
      overflow-y: auto;
    }

    .content-container {
      // background: #f1f2f7;
      flex: 1;
      // position: absolute;
      // right: 0px;
      // top: 0px;
      // bottom: 0px;
      // left: 230px;
      overflow-y: scroll;
      padding: 10px;

      .breadcrumb-container {
        //margin-bottom: 15px;
        .title {
          width: 200px;
          float: left;
          color: #475669;
        }

        .breadcrumb-inner {
          float: left;
        }
      }

      .content-wrapper {
        background-color: #fff;
        box-sizing: border-box;
      }
    }

    .menu-icon {
      display: inline-block;
      min-width: 30px;
      margin-right: 2px;
      vertical-align: sub;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
    }
  }
}
@media only screen and (max-width: 800px) {
  .header{
    .logo{
      border-width: 0px;
      border-style: none;
    }
  }
  .menu-collapsed{
    display: none;
  }
  .menu-expanded{
    z-index: 100;
    position: absolute;
    height: 100%;
  }
  .logo-width{
    position: absolute;
    &+#route .tools{
      display: none;
    }
  }
  .userinfo{
    padding-right: 0px;
    float: none;
    position: absolute;
    right: 0px;
    width: 150px;
}
.logo-collapse-width{
    width: 0px;
    &+#route .tools{
      position: absolute;
      left: 5px;
    }
}
}
.NumFooter{
  padding: 20px 20px 0px;
  text-align: right;
}
</style>
